import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function BannerSection(props) {
  return (
    <div
      className={`columns is-vcentered banner-section invert-link-color ${
        props.hasTextCentered ? "has-text-centered" : ""
      }`}
    >
      <div className="column is-3"></div>
      <div className="column is-4">
        <ImageMeta
          cloudName="nuvolum"
          publicId={props.post.bannerSection.logoId}
          width="auto"
          responsive
        ></ImageMeta>
      </div>
      <div className="column is-1"></div>
      <div
        className="column"
        dangerouslySetInnerHTML={createHtml(
          converter.makeHtml(props.post.bannerSection.text)
        )}
      ></div>
      <div className="column is-2"></div>
    </div>
  )
}

export default BannerSection
