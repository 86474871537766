import React from "react"
import PropTypes from "prop-types"
import ImageMeta from "../../components/ImageMeta"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function TeamMember(props) {
  return (
    <div className="column is-5">
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.publicId}
        responsive
        width="auto"
      ></ImageMeta>
<div className="has-text-centered team-member-item">
                    <h4 className="" style={{margin: 0, marginBottom: "0"}}>{props.staffName}</h4>
              <p className="">
                {props.staffTitle}
              </p>
</div>
      {/* <h6 className="image-caption staff staff-name">{props.staffName}</h6>
      <h6 className="image-caption staff staff-title light">
        {props.staffTitle}
      </h6> */}
    </div>
  )
}

TeamMember.propTypes = {
  publicId: PropTypes.string,
  staffName: PropTypes.string,
  staffTitle: PropTypes.string,
}

function TeamRow(props) {
  return (
    <div className={`columns staff-row ${props.last ? "last" : ""}`}>
      <div className="column is-2"></div>
      {props.members.map(member => {
        return (
          <TeamMember
            key={member.staffMember.imageId}
            publicId={member.staffMember.imageId}
            staffName={member.staffMember.staffName}
            staffTitle={member.staffMember.staffTitle}
          />
        )
      })}
      <div className="column is-2"></div>
    </div>
  )
}

TeamRow.propTypes = {
  members: PropTypes.array,
}

function MeetOurTeam(props) {
  var meetOurTeamClasses = classNames({
    "body-section meet-our-team": true,
    "color-back": props.invert,
  })

  var members = props.post.meetOurTeam.staffImages

  var memberRows = []
  var size = 4

  for (var i = 0; i < members.length; i += size) {
    memberRows.push(members.slice(i, i + size))
  }

  return (
    <section className={meetOurTeamClasses}>
      <div className="columns meet-our-team-heading">
        <div className="column is-4"></div>
        <div
          className="column mobile-col has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.meetOurTeam.text)
          )}
        ></div>
        <div className="column is-4"></div>
      </div>

      {memberRows.map((row, i) => {
        return (
          <TeamRow
            members={row}
            last={i == memberRows.length - 1 ? true : false}
          />
        )
      })}
    </section>
  )
}

MeetOurTeam.propTypes = {
  invert: PropTypes.bool,
  post: PropTypes.shape({
    meetOurTeam: PropTypes.shape({
      text: PropTypes.string,
      staffImages: PropTypes.array,
    }).isRequired,
  }).isRequired,
}

export default MeetOurTeam
